<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddRecord">{{ $t('general.add') }}</a-button>
							<a-button class="btn btn-success ml5" icon="reload" @click="onReload" />
						</div>
					</div>
					<div class="row pt15">
						<div class="col-md-12">
							<a-input allowClear v-model="searchText" @change="onSearch" :placeholder="$t('suppliersModule.search.placeholder')" />
						</div>
						<div class="col-md-12 pt10">
							<a-table :columns="columns" :dataSource="searchText == '' ? suppliersList : suppliersListFiltered" :pagination="{ pageSize: 25 }">
								<div slot="fiscal_name" slot-scope="record" class="text-left">
									{{ record.taxInfo ? record.taxInfo.business_name : '-' }}
								</div>
								<div slot="nationality" slot-scope="record">
									{{ record.nationality == 'foreign' ? 'Extranjero' : 'Nacional' }}
								</div>
								<div slot="phones" slot-scope="record">
									<div v-html="record.phone"></div>
								</div>
								<div slot="action" slot-scope="record">
									<a-tooltip placement="top">
										<template slot="title">
											<span>Detalle</span>
										</template>
										<a-button class="ml5" type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
									</a-tooltip>
									<a-tooltip placement="top">
										<template slot="title">
											<span>{{ record.active ? 'Deshabilitar' : 'Activar' }}</span>
										</template>
										<a-button class="ml5" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="toggleRecord(record.id)" />
									</a-tooltip>
									<a-tooltip placement="top" v-if="!record.hasPassword">
										<template slot="title">
											<span>Enviar accesos</span>
										</template>
										<a-button class="ml5" size="small" icon="mail" @click="sendCredentials(record.id)" />
									</a-tooltip>
								</div>
							</a-table>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
	name: 'suppliersView',
	data() {
		return {
			supplier_type: '',
			searchText: '',
			suppliersListFiltered: [],
		}
	},
	computed: {
		...mapGetters('suppliers', ['suppliersList', 'spinnerLoader', 'spinnerLoaderLabel']),
		columns() {
			let columns = [
				{
					title: this.$t('suppliersModule.tableHeaders.id'),
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: this.$t('suppliersModule.tableHeaders.name'),
					dataIndex: 'full_name',
					key: 'full_name',
				},
				{
					title: 'Razón Social',
					align: 'center',
					scopedSlots: { customRender: 'fiscal_name' },
				},
				{
					title: this.$t('suppliersModule.tableHeaders.email'),
					dataIndex: 'email',
					key: 'email',
				},
				{
					title: this.$t('suppliersModule.tableHeaders.phones'),
					scopedSlots: { customRender: 'phones' },
				},
				{
					title: this.$t('general.actions'),
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '10%',
				},
			]
			return columns
		},
	},
	mounted() {
		this.initModule()
	},
	methods: {
		initModule() {
			let payload = {
				supplier_type: this.supplier_type,
			}
			this.$store.dispatch('suppliers/GET', payload)
		},
		onReload() {
			this.initModule()
		},
		onAddRecord() {
			this.$router.push('/suppliers/new')
		},
		onEditRecord(id) {
			this.$store.dispatch('suppliers/GET_ONE', id).then((response) => {
				this.$router.push(`/suppliers/${response.general.id}`)
			})
		},
		toggleRecord(id) {
			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: this.$t('general.deleteMessage.message'),
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('suppliers/TOGGLE', {
						section: 'suppliers',
						id,
					})
				}
			})
		},
		onSearch(event) {
			let { value } = event.target
			this.suppliersListFiltered = []
			if (value != '') {
				this.suppliersListFiltered = this.suppliersList.filter((obj) =>
					Object.values(obj).some((val) => {
						if (typeof val == 'string' && val.toLowerCase().includes(value.toLowerCase())) {
							return true
						}
					}),
				)
			}
		},
		sendCredentials(id) {
			let list = this.searchText == '' ? this.suppliersList : this.suppliersListFiltered
			let record = list.find(e => e.id == id)

			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: `Se van a enviar datos de ingreso al sistema a <b>${record.full_name}</b>.<br>¿Deseas continuar?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('suppliers/SEND_CREDENTIALS', record.id)
				}
			})
		},
	},
}
</script>